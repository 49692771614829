<template>
  <transition name="fade">
    <div class="v-modal" v-if="active">
      <div class="v-modal__overlay" @click="close" />
      <div class="v-modal__content">
        <CloseIcon @click="close" class="v-modal__close" />
        <DarkLogoIcon class="v-modal__logo" />
        <div class="v-modal__success" v-if="errorServer">
          <div class="v-modal__success-empty" />
          <div class="v-modal__success-text">
            <ErrorIcon class="v-modal__success-icon" />
            Failed to send <br />
            message. Try <br />
            again please
          </div>
          <button class="v-modal__btn" @click="close">
            {{ $t("words.ok") }}
          </button>
        </div>
        <div class="v-modal__form" v-else-if="!success">
          <div class="v-modal__title">{{ $t("form.title") }}</div>
          <div class="v-modal__field">
            <div class="v-modal__label">
              {{ $t("form.firstName") }}<span>*</span>
            </div>
            <input
              v-model="form.name"
              :placeholder="$t('form.firstNamePlaceholder')"
              class="v-modal__input"
              :class="{ error: errors.name }"
              @input="errors.name = false"
            />
          </div>
          <div class="v-modal__field">
            <div class="v-modal__label">
              {{ $t("form.email") }}<span>*</span>
            </div>
            <input
              v-model="form.email"
              :placeholder="$t('form.emailPlaceholder')"
              class="v-modal__input"
              :class="{ error: errors.email }"
              @input="errors.email = false"
            />
          </div>
          <div class="v-modal__field">
            <div class="v-modal__label">
              {{ $t("form.message") }}<span>*</span>
            </div>
            <textarea
              v-model="form.message"
              :placeholder="$t('form.messagePlaceholder')"
              class="v-modal__textarea"
              :class="{ error: errors.message }"
              @input="errors.message = false"
            />
          </div>
          <button class="v-modal__btn" @click="sendTicket">
            {{ $t("words.send") }}
          </button>
        </div>
        <div class="v-modal__success" v-else-if="success">
          <div class="v-modal__success-empty" />
          <div class="v-modal__success-text">
            <SuccessIcon class="v-modal__success-icon" />
            Complete!
          </div>
          <button class="v-modal__btn" @click="close">
            {{ $t("words.ok") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, reactive, ref } from "vue";
import CloseIcon from "@/components/ui-kit/icons/CloseIcon.vue";
import DarkLogoIcon from "@/components/ui-kit/icons/DarkLogoIcon.vue";
import SuccessIcon from "@/components/ui-kit/icons/SuccessIcon.vue";
import ErrorIcon from "@/components/ui-kit/icons/ErrorIcon.vue";

const emit = defineEmits(["update:active"]);
defineProps({
  active: Boolean,
});

const success = ref(false);
const errorServer = ref(false);
const form = reactive({
  name: "",
  email: "",
  message: "",
});
const errors = reactive({
  name: false,
  email: false,
  message: false,
});
const close = () => {
  form.message = "";
  form.name = "";
  form.email = "";
  success.value = false;
  errorServer.value = false;
  emit("update:active", false);
};
const sendTicket = async () => {
  errors.email = errors.name = errors.message = false;
  if (!form.name) errors.name = true;
  if (!form.email || !/^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/i.test(form.email))
    errors.email = true;
  if (!form.message) errors.message = true;
  if (errors.name || errors.email || errors.message) return;
  // eslint-disable-next-line no-undef
  if (grecaptcha) {
    // eslint-disable-next-line no-undef
    grecaptcha
      .execute("6Le_NWklAAAAANTv2slOHFk64oZFJQsRtM2Mt6OK", {
        action: "validate_captcha",
      })
      .then(function (token) {
        // add token value to form
        fetch(`${process.env.VUE_APP_API_URL}feedback/add`, {
          method: "POST",
          body: JSON.stringify({ ...form, captcha: token }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.code === 200) success.value = true;
            else {
              errorServer.value = true;
            }
          });
      });
  }
};
</script>

<style lang="scss" scoped>
.v-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.9);
  }
  &__content {
    position: relative;
    z-index: 1;
    background: #ffffff;
    border-radius: 15px;
    padding: 24px 56px;
    width: 456px;
    display: flex;
    flex-direction: column;
    height: 568px;
    @include below($mobileLrg) {
      width: 90%;
      padding: 36px 24px;
    }
  }
  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__logo {
    margin: 0 auto;
    display: block;
  }
  &__form {
    margin-top: 26px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 18px;
    text-align: center;
  }
  &__btn {
    width: 200px;
    padding: 9px;
    background: #1c59c6;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    display: block;
    text-align: center;
    margin: auto auto 0;
    transition: background 0.2s ease;
    color: white;
    cursor: pointer;
    &:hover {
      background: #194faf;
    }
  }
  &__field {
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__label {
    font-size: 14px;
    color: #2a2c2f;
    margin-bottom: 8px;
    span {
      color: #1c59c6;
    }
  }
  &__input {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 40px;
    font-family: "Inter";
    font-size: 14px;
    padding: 6px 16px;
    width: 100%;
    outline: none;
    transition: border 0.2s ease;
    &.error {
      border-color: rgba(206, 80, 80, 0.82);
    }
    &:focus,
    &:active {
      border: 1px solid #b4b4b4;
    }
    &::placeholder {
      color: #6f727a;
      font-family: "Inter";
    }
  }
  &__textarea {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-family: "Inter";
    font-size: 14px;
    padding: 10px 16px;
    width: 100%;
    outline: none;
    transition: border 0.2s ease;
    resize: none;
    height: 96px;
    &.error {
      border-color: rgba(206, 80, 80, 0.82);
    }
    &:focus,
    &:active {
      border: 1px solid #b4b4b4;
    }
    &::placeholder {
      color: #6f727a;
      font-family: "Inter";
    }
  }
  &__success {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .v-modal__btn {
      margin-top: 0;
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 8px;
      color: #2a2c2f;
      font-weight: 600;
      font-size: 24px;
    }
    &-icon {
      width: 56px;
      height: 31px;
    }
  }
}
</style>
