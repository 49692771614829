<template>
  <div class="solution">
    <v-container>
      <h2 class="title">{{ $t("solution.title") }}</h2>
      <div class="solution__content">
        <SolutionIcon class="solution__icon" />
        <SolutionMobileIcon class="solution__icon--mobile" />
      </div>
      <div class="solution__paper">
        <div class="solution__paper-title">{{ $t("solution.text") }}</div>
        <div class="solution__items">
          <div class="solution__item">
            <v-icon name="center" class="solution__item-icon" />
            <p v-html="$t('solution.sharingUnused')" />
          </div>
          <div class="solution__item">
            <v-icon name="gauss" class="solution__item-icon" />
            <p v-html="$t('solution.gaussBlockchain')" />
          </div>
          <div class="solution__item">
            <v-icon name="about" class="solution__item-icon" />
            <p v-html="$t('solution.kubernetes')" />
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SolutionIcon from "@/components/ui-kit/icons/SolutionIcon.vue";
import SolutionMobileIcon from "@/components/ui-kit/icons/SolutionMobileIcon.vue";

export default {
  name: "SolutionBlock",
  components: { SolutionMobileIcon, SolutionIcon },
};
</script>

<style lang="scss" scoped>
.solution {
  padding: 100px 0 0;
  &__content {
    margin-top: 85px;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
  &__icon {
    width: 95%;
    height: 665px;
    @include below($notebook) {
      display: none;
    }
    &--mobile {
      display: none;
      width: 100%;
      height: 440px;
      @include below($notebook) {
        display: block;
      }
    }
  }
  &__paper {
    margin: 16px 0;
    background: radial-gradient(
      30.15% 44.73% at 50% 50%,
      #30313a 0%,
      #202126 100%
    );
    border-radius: 24px;
    padding: 60px;
    @include below($notebook) {
      padding: 50px 16px 40px;
    }
    &-title {
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      color: #e6ecf5;
      margin-bottom: 85px;

      @include below($notebook) {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 50px;
      }
    }
  }
  &__items {
    display: flex;
    justify-content: space-between;
    @include below($notebook) {
      gap: 16px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: #e6ecf5;
    &-icon {
      width: 90px;
      height: 95.29px;
      margin: 0 auto;
      @include below($notebook) {
        width: 54.22px;
        height: 57.41px;
      }
    }
    p {
      white-space: pre-line;
      text-align: center;
      font-weight: 700;
      font-size: 20px;
      @include below($notebook) {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}
</style>
